<header>
  <h1>{{ headingTitle }}</h1>
  <div>
    <span>Error Code:</span><span>{{ errorCode }}</span>
  </div>
  <div>{{ headingDescription }}</div>
</header>
<nav>
  <div *ngIf="errorCode !== 403 && (hasHistory$ | async)">
    <h5>Back to the previous page</h5>
    <re-button
      class="re-primary"
      leftIconSrc="assets/images/cmi-icons/icon-reg_chevron-left.svg"
      leftIconAlt="left arrow icon"
      (clicked)="navigateBack()"
      >Back</re-button
    >
  </div>
  <div *ngIf="hasPermission$ | async">
    <h5>Helpful links:</h5>
    <a class="re-standalone" routerLink="/dashboard" [queryParams]="yonyParams">Y on Y dashboard</a>
    <a class="re-standalone" routerLink="/dashboard" [queryParams]="qonqParams">Q on Q dashboard</a>
    <a class="re-standalone" routerLink="/dashboard" [queryParams]="timeSeriesYonYParams"
      >Time series (y on y)</a
    >
    <a class="re-standalone" routerLink="/dashboard" [queryParams]="timeSeriesQonQParams"
      >Time series (q on q)</a
    >
  </div>
  <div>
    <h5>Contact an admin</h5>
    <div>Email: CMI_Support&#64;GallagherRe.com</div>
  </div>
</nav>
