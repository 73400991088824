import { Lob, Period } from '@cmi/shared/models';
import {
  KpiNarrative,
  KpiNarrativeSlideout,
  SummaryDashboard,
  TimeSeriesDashboard
} from '@cmi/store/dashboard';
import { createAction, props } from '@ngrx/store';

const PREFIX = '[Dashboard]';

export const dashboardInitialise = createAction(`${PREFIX} Initialise`);
export const dashboardInitialiseComplete = createAction(
  `${PREFIX} Initialise Complete`,
  props<{
    periods: Period[];
    lobs: Lob[];
    selectedDashboard: string;
    selectedPeriod: string;
    selectedPeriodTo: string;
    selectedLocal: string;
    selectedLobs?: number[];
  }>()
);
export const dashboardUpdateLobs = createAction(`${PREFIX} Update LOBs`, props<{ lobs: Lob[] }>());
export const dashboardUpdatePeriods = createAction(
  `${PREFIX} Update Periods`,
  props<{ periods: Period[] }>()
);
export const dashboardSelectedDashboard = createAction(
  `${PREFIX} Selected Dashboard`,
  props<{ selectedDashboard: string }>()
);
export const dashboardSelectedPeriod = createAction(
  `${PREFIX} Selected Period`,
  props<{ selectedPeriod: string }>()
);
export const dashboardSelectedPeriodNoEffect = createAction(
  `${PREFIX} Selected Period No Effect`,
  props<{ selectedPeriod: string }>()
);
export const dashboardSelectedPeriodFrom = createAction(
  `${PREFIX} Selected Period From`,
  props<{ selectedPeriodFrom: string }>()
);
export const dashboardSelectedPeriodTo = createAction(
  `${PREFIX} Selected Period To`,
  props<{ selectedPeriodTo: string }>()
);
export const dashboardSelectedLocal = createAction(
  `${PREFIX} Selected Local`,
  props<{ selectedLocal: string }>()
);
export const dashboardUpdateShowAlert = createAction(
  `${PREFIX} Update Show Alert`,
  props<{ showAlert: boolean }>()
);

//
// Executive Summary Modal
//
export const dashboardUpdateExecutiveSummary = createAction(
  `${PREFIX} Update Executive Summary`,
  props<{ executiveSummary: string }>()
);
export const dashboardExecutiveSummaryOpen = createAction(
  `${PREFIX} Executive Summary Open`,
  props<{ executiveSummaryOpen: boolean }>()
);
export const dashboardExecutiveSummaryModalOpen = createAction(
  `${PREFIX} Executive Summary Modal Open`
);

//
// Filter LOB Modal
//
export const dashboardFilterLobsModalOpen = createAction(`${PREFIX} Filter LOBs Modal Open`);
export const dashboardSelectedLobs = createAction(
  `${PREFIX} Selected LOBs`,
  props<{ selectedLobs?: number[] }>()
);
export const dashboardFilterLobsModalSave = createAction(
  `${PREFIX} Filter LOBs Modal Save`,
  props<{ selectedLobs?: number[] }>()
);

//
// Grid Actions
//
export const dashboardUpdateGrid = createAction(`${PREFIX} Update Grid`);
export const dashboardUpdateYonYData = createAction(
  `${PREFIX} Update YonY Data`,
  props<{ yonyGridData: SummaryDashboard[] }>()
);
export const dashboardUpdateQonQData = createAction(
  `${PREFIX} Update QonQ Data`,
  props<{ qonqGridData: SummaryDashboard[] }>()
);
export const dashboardUpdateTimeSeriesYonYData = createAction(
  `${PREFIX} Update Time Series YonY Data`,
  props<{ timeSeriesYonYGridData: TimeSeriesDashboard[] }>()
);
export const dashboardUpdateTimeSeriesQonQData = createAction(
  `${PREFIX} Update Time Series QonQ Data`,
  props<{ timeSeriesQonQGridData: TimeSeriesDashboard[] }>()
);

//
// KPI Narrative
//
export const dashboardOpenKpiNarrativeSlideout = createAction(
  `${PREFIX} Open KPI Narrative Slideout`,
  props<{ kpiNarrativeData: KpiNarrativeSlideout }>()
);

export const dashboardInitialiseKpiNarrative = createAction(
  `${PREFIX} Intialise KPI Narrative`,
  props<{ kpi: string; subLobId: string }>()
);

export const dashboardInitialiseKpiNarrativeComplete = createAction(
  `${PREFIX} Intialise KPI Narrative Complete`,
  props<{ kpiNarrativeList: KpiNarrative[] }>()
);

export const dashboardDownloadFile = createAction(
  `${PREFIX} Download File`,
  props<{ filename: string; busyIndicator: string }>()
);
