import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PdfViewerNotifyService } from '@cmi/shared/services';
import { Kpi } from '@cmi/store/app';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-kpi-cell',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './kpi-cell.component.html',
  styleUrl: './kpi-cell.component.scss'
})
export class KpiCellComponent implements ICellRendererAngularComp {
  colourClass = '';
  img = { src: '', alt: '' };
  filename = '';
  section = '';
  isPeriodEnabled = true;
  periodLabel!: string;

  constructor(private pdfViewerNotifyService: PdfViewerNotifyService) {}

  agInit(params: ICellRendererParams) {
    this.filename = params?.data?.pdfFilename;
    this.section = params?.value?.section;
    this.isPeriodEnabled = !params?.data?.disabled;
    this.periodLabel = params?.data?.periodLabel?.replace('(unavailable)', '');

    switch (params?.value?.kpi as Kpi) {
      case Kpi.FavourableUp: {
        this.colourClass = 're-green';
        this.img = this.imageArrowUp;
        break;
      }
      case Kpi.UnfavourableUp: {
        this.colourClass = 're-red';
        this.img = this.imageArrowUp;
        break;
      }
      case Kpi.FavourableDown: {
        this.colourClass = 're-green';
        this.img = this.imageArrowDown;
        break;
      }
      case Kpi.UnfavourableDown: {
        this.colourClass = 're-red';
        this.img = this.imageArrowDown;
        break;
      }
      case Kpi.Stable: {
        this.colourClass = 're-yellow';
        this.img = this.imageArrowRight;
        break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(_params: ICellRendererParams): boolean {
    return false;
  }

  openPdf() {
    if (this.filename) {
      this.pdfViewerNotifyService.notify(this.filename, this.section);
    }
  }

  private get imageArrowUp() {
    return { src: 'assets/images/cmi-icons/icon-reg_arrow-up.svg', alt: 'arrow up' };
  }

  private get imageArrowDown() {
    return { src: 'assets/images/cmi-icons/icon-reg_arrow-down.svg', alt: 'arrow down' };
  }

  private get imageArrowRight() {
    return { src: 'assets/images/cmi-icons/icon-reg_arrow-right.svg', alt: 'arrow right' };
  }
}
