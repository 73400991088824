<footer [style.paddingLeft]="paddingOffset">
  <div>
    <div>
      <!-- <a href="https://www.ajg.com/gallagherre/contact-us/" target="_blank">Contact Us</a> -->
      <a href="https://www.ajg.com/gallagherre/privacy-notice/" target="_blank">Privacy Policy</a>
      <a href="https://policy.cookiereports.com/5a4e9bca-en-gb.html" class="CookieReportsLink"
        >Cookie Policy</a
      >
    </div>
    <div>© 2024 Arthur J. Gallagher & Co.</div>
  </div>
</footer>
