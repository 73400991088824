<div
  class="re-focus-keydown"
  [class]="colourClass"
  tabindex="0"
  (click)="openPdf()"
  (keydown.enter)="openPdf()"
  *ngIf="isPeriodEnabled; else disabled_period">
  <img [src]="img.src" [alt]="img.alt" height="16" />
</div>
<ng-template #disabled_period>
  <span
    ><strong>{{ periodLabel }} data is unavailable - </strong>We are currently updating this data,
    check back later for updates.</span
  >
</ng-template>
